* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}

body {
  background-color: white;
  font-family: 'Helvetica Neue', 'Arial', sans-serif !important;
}

/* ScrollToTopButton.css */

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-button {
  background-color: #deba75;
  color: white;
  font-size: 20px;
  padding: 7px 14px 7px 14px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
  border: none;
  position: relative;
  overflow: hidden;
}

.scroll-button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease;
  z-index: 0;
}

.scroll-button:hover:before {
  transform: translate(-50%, -50%) scale(1);
}

.scroll-button:hover {
  background-color: #7d2c2a;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

.scroll-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 172, 150, 0.5);
}

.scroll-button svg {
  width: 20px;
}


/*/////////////////// header.css/////////////////// */

.nav-item.active,
.Navdropdownitem.active {
  color: #deba75 !important;
  background-color: transparent !important;
}

.headermain {
  transition: background-color 0.3s ease, transform 0.3s ease, padding 0.3s ease !important;
}

.gradient-bg {
  background: linear-gradient(113.03deg, #fff5f2 0%, #f3e9fa 100%);
  border-bottom: 2px solid rgb(36, 24, 26) !important;
}

.headingText1 {
  color: #deba75;
  font-size: 14px;
}

.headingText2 {
  color: #40444F;
  font-size: 40px;
  line-height: 42px;
}

.headingTextMain {
  width: 50% !important;
}

.headerLogo {
  height: 80px;
}

:root {
  --trans-property: all 0.3s ease;
}

.social-icon {
  display: flex;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 3px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  transition: var(--trans-property), transform 0.2s;
}

.social-icon i {
  margin: auto;
  padding: 3px;
  font-size: 18px;
  color: #7d2c2a;
  z-index: 1;
  transition: color 0.3s, transform 0.2s;
}

.header_social_icn1 {
  margin: auto;
  padding: 3px;
  font-size: 22px !important;
  color: #7d2c2a;
  z-index: 1;
  transition: color 0.3s, transform 0.2s;
}

.social-icon:after {
  content: "";
  width: var(--link-size);
  height: var(--link-size);
  position: absolute;
  transform: translate(0, var(--link-size));
  border-radius: 50%;
  transition: var(--trans-property);
}

.social-icon:hover {
  transform: translateY(-4px);
  background-color: #deba75;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.social-icon:hover i {
  color: white;
  transform: scale(1.1);
}

.social-icon:hover:after {
  transform: translate(0) scale(1.2);
}

.navbar {
  background-color: #f8f9fa;
}

.NavbarToggle {
  font-size: 30px;
  color: #7d2c2a;
}

.navbar-brand {
  font-weight: bold;
}

.nav-link:hover {
  color: #ffffff !important;
  transition: transform 0.3s, color 0.4s !important;
  border-radius: 5px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item:active {
  background-color: white;
}

.nav-item,
.dropdown-toggle {
  color: #40444F !important;
}

.nav-item:hover,
.dropdown-toggle:hover {
  color: #deba75 !important;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.Navdropdownitem {
  background-color: #FFFFFF;
  border-radius: 7px;
}

.Navdropdownitem:hover {
  background-color: #deba75 !important;
  color: #FFFFFF !important;
  border-radius: 5px;
  transition: transform 0.3s, background-color 0.6s !important;
}

.dropdown-menu {
  padding: 0px !important;
  margin: 0px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.navDropDownIcn {
  font-size: 14px !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  border: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* // */

.jewelry-card {
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  border: none !important;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jewelry-card:hover {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
}

.jewelry-card img {
  height: auto;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.jewelry-card:hover img {
  transform: scale(1.1);
}

.card-title {
  font-size: 1.8rem !important;
  color: #ff6f61 !important;
  margin-top: 15px !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4) !important;
  text-align: center !important;
  font-weight: bold !important;
}

/*  */

.min-vh-100 {
  min-height: 100vh;
}

.bg-light {
  background-color: #fafafa;
}

/* ProductSlider.css */

.slider-container {
  padding: 40px;
  background: linear-gradient(135deg, #f4f4f4, #e0e0e0);
  border-radius: 15px;
}

.product-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.product-image {
  transition: all 0.3s ease;
}

.product-image:hover {
  filter: brightness(1.15);
}

.custom-arrow.slick-prev {
  left: 0px;
}

.custom-arrow.slick-next {
  right: 0px;
}

.slick-prev:before,
.slick-next:before {
  color: #920303 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

/* /// */

.FeaturesSectionRow {
  background: radial-gradient(circle, #e8e6e6 0, #f9f9f9 100%) !important;
}

.feature-card {
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  cursor: pointer;
  justify-content: center;
}

.featuretitle {
  font-size: 16px;
}

/* /// */

.video {
  height: auto;
  width: 100%;
  border-radius: 15px;
}

.JewelryVidSecTxt1 {
  color: #333;
}

/* //// */

.message-input {
  border: 1px solid #ced4da;
  transition: border-color 0.3s;
}

.message-input:focus {
  border-color: #6c757d;
  box-shadow: 0 0 5px rgba(108, 117, 125, 0.5);
}

.contactTxt001 {
  font-size: 20px;
  color: black;
  text-decoration: none;
}

.sendmsgbutton {
  background: linear-gradient(135deg, #7d2c2a, #9e5d5c);
  color: white;
  font-weight: 600;
  padding: 8px 18px;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sendmsgbutton:hover {
  background: linear-gradient(135deg, #9e5d5c, #7d2c2a);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}



.addreshTxt {
  font-size: 18px;
}

.emailTxt {
  font-size: 18px;
}

.phoneNumTxt {
  font-size: 18px;
}


@media (max-width: 768px) {
  .card-title {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 600px) {
  .slick-prev {
    left: 0px !important;
  }

  .slick-next {
    right: 0px !important;
  }
}

@media (max-width: 576px) {
  .card-title {
    font-size: 1.2rem !important;
  }

  .featuretitle {
    font-size: 14px !important;
  }

  .text-section2 {
    font-size: 22px !important;
  }
}